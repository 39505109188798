import moment from "moment"
import React from "react"
import { GiWeight } from "react-icons/gi"
import { HiChevronRight } from "react-icons/hi"
import { PiFireBold, PiFireDuotone } from "react-icons/pi"
import { useNavigate } from "react-router-dom"
import { getItem } from "src/modules/navigation/screens/plan-view/workout-view/Weight/View/Content/hooks/ncachedContent"
import { styled } from "styled-components"

// import { Container } from './styles';

const ActionButton = styled.button`
    width: 100%;
    justify-content: space-between;
    align-items: center;

    > div {
        align-items: center;
        flex-direction: row;
        > div {
            padding-left: 10px;
            flex-direction: column;
        }
    }
    padding: 10px;

    background-color: var(--background);
    border: 1px solid var(--border);
    border-radius: 5px;

    h5 {
        font-weight: normal;
    }
`

const StartedShortcut: React.FC = () => {
    const data = getItem<{ pid: string; wid: string; date: string }>(
        "current-w"
    )

    const nav = useNavigate()

    function GoToWorkout() {
        if (data) nav(`/plans/workouts/view?pid=${data.pid}&wid=${data.wid}`)
    }

    if (data) {
        return (
            <ActionButton onClick={GoToWorkout}>
                <div>
                    <PiFireDuotone size={24} />
                    <div>
                        <h4>Retomar treino em Andamento</h4>
                        <h5>
                            Você iniciou um treino em{" "}
                            {moment(data.date).format("LLL")}
                        </h5>
                    </div>
                </div>
                <HiChevronRight size={24} />
            </ActionButton>
        )
    }
    return <React.Fragment />
}

export default StartedShortcut
