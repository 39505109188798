import { Weight } from "@fithub-shared/interfaces/workout"
import React, { useEffect, useState } from "react"
import {
    Controls,
    ExerciseContent,
    ExpandableExc,
    PlayStopButton,
    WeightContainer,
} from "./styles"
import {
    MdCheckCircle,
    MdCheckCircleOutline,
    MdChevronRight,
} from "react-icons/md"
import { IoPlayOutline, IoStopOutline } from "react-icons/io5"

import Content from "./Content"
import { WeightExercise } from "@fithub-shared/interfaces/exercise"
import useModal, { Modal } from "src/components/Modal"
import ModalAviso from "./ModalAviso"
import { useQuery } from "react-query"
import api from "src/api"
import { GetParameter } from "src/assets/functions"
import { useAuthContext } from "src/contexts/auth"
import { ExecHistory } from "@fithub-shared/trainee/interfaces"
import {
    convertCachedToExec,
    getItem,
    removeCachedById,
    removeItem,
    removeOnFinish,
    setItem,
} from "./Content/hooks/ncachedContent"
import LoadingIndicator from "src/components/LoadingIndicator"
import FixedDiv from "src/components/FixedFooter"

// import { Container } from './styles';

const WeightView: React.FC<{ content: Weight }> = ({ content }) => {
    function GetExercisesIDs() {
        return content.exercises.length > 0
            ? `(${content.exercises.map((e) => `'${e.linkId}'`).join(", ")})`
            : undefined
    }

    const auth = useAuthContext()

    const plan = GetParameter("pid")
    const workout = GetParameter("wid")
    const userid = auth.user?.id!!

    const history = useQuery(
        ["history", plan, workout],
        () =>
            api
                .post<ExecHistory>("/chargepro/load", {
                    userid,
                    plan,
                    workout,
                    exercisesIds: GetExercisesIDs(),
                })
                .then((res) => {
                    setStarted(res.data.current !== undefined)
                    return {
                        ...res.data,
                    }
                }),
        {
            refetchOnWindowFocus: true,
            cacheTime: 120000,
            staleTime: 120000,
        }
    )

    const [exercise, setExercise] = useState<WeightExercise>()
    const [started, setStarted] = useState(history.data?.current !== undefined)
    const [processing, setProcessing] = useState(false)

    useEffect(() => {
        console.log("Refetched history!")
    }, [])

    const finishModal = useModal()

    async function HandleStart() {
        try {
            setProcessing(true)
            if (started) {
                const id = history.data?.current?.id

                if (!id) {
                    alert("ID Erro")
                    return
                }
                await api.post("chargepro/finish", {
                    userid,
                    id,
                    exercises: convertCachedToExec(id),
                })
                history.refetch()
                removeOnFinish()
                setCompleted([])
                setStarted(false)
                setExercise(undefined)
            } else {
                await api.post("chargepro/start", {
                    userid,
                    plan,
                    workout,
                    trainerid: auth.trainer?.id,
                })
                setItem("current-w", {
                    pid: plan,
                    wid: workout,
                    date: Date.now().toString(),
                })
                history.refetch()
            }
        } catch (err) {
            alert((err as any).response.data.message)
        } finally {
            setProcessing(false)
        }
    }

    const [completed, setCompleted] = useState<string[]>(
        getItem("completed") ?? []
    )

    function AddComplete(id: string, valid: boolean) {
        if (valid && !completed.includes(id)) {
            setCompleted([...completed, id])
            setItem("completed", [...completed, id])
        } else if (!valid && completed.includes(id)) {
            setCompleted((old) => {
                const actual = old.splice(completed.indexOf(id), 1)
                setItem("completed", actual)
                return actual
            })
        }
    }

    if (history.isLoading || history.isError || processing) {
        return <LoadingIndicator isLoading={!history.isError} />
    } else
        return (
            <>
                <Modal
                    isOpen={exercise !== undefined}
                    onClose={() => setExercise(undefined)}
                    noStyles
                >
                    {exercise && (
                        <ExerciseContent>
                            <Content
                                started={started ?? false}
                                exercise={exercise}
                                dismiss={() => setExercise(undefined)}
                                hint={history.data?.hints?.find(
                                    (h) => h.exercise === exercise.linkId
                                )}
                                currentId={history.data?.current?.id}
                                onValidate={(valid) =>
                                    AddComplete(exercise.id, valid)
                                }
                            />
                        </ExerciseContent>
                    )}
                </Modal>

                <WeightContainer
                    interactable={true}
                    className={`page ${exercise ? "blur" : ""}`}
                >
                    {started && (
                        <ModalAviso
                            isOpen={finishModal.isOpen}
                            onConfirm={HandleStart}
                            onCancel={finishModal.closeModal}
                        />
                    )}

                    {content.exercises.map((e, index) => (
                        <ExpandableExc
                            key={e.id + index}
                            onClick={() => setExercise(e)}
                        >
                            {started ? (
                                completed.includes(e.id) ? (
                                    <MdCheckCircle size={22} color="#3f5" />
                                ) : (
                                    <MdCheckCircleOutline
                                        size={22}
                                        color="#777"
                                    />
                                )
                            ) : (
                                <MdChevronRight size={22} color="white" />
                            )}
                            <span />
                            <p style={{ fontWeight: "bold" }}>{e.name}</p>
                        </ExpandableExc>
                    ))}
                    <FixedDiv>
                        {started ? (
                            <Controls>
                                {processing ? (
                                    <p>Finalizando...</p>
                                ) : (
                                    <PlayStopButton
                                        onClick={finishModal.openModal}
                                    >
                                        <IoStopOutline />
                                        <p>FINALIZAR TREINO</p>
                                    </PlayStopButton>
                                )}
                            </Controls>
                        ) : (
                            <Controls>
                                <div />
                                {processing ? (
                                    <p>Iniciando...</p>
                                ) : (
                                    <PlayStopButton onClick={HandleStart}>
                                        <IoPlayOutline />
                                        <p>INICIAR</p>
                                    </PlayStopButton>
                                )}
                                <div />
                            </Controls>
                        )}
                    </FixedDiv>
                </WeightContainer>
            </>
        )
}

export default WeightView
