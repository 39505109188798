import React, { useContext } from "react"
import { useQuery } from "react-query"
import api from "src/api"
import AuthContext, { Trainer } from "src/contexts/auth"
import { ListItem, Listing, Wrapper } from "./styles"
import { useNavigate } from "react-router-dom"

import Avatar from "src/components/avatar"
import FhLogoInline from "src/components/FhLogoInline"
import { BsChevronRight } from "react-icons/bs"
import { FiUserX } from "react-icons/fi"
import { Hyperlink } from "../trainee-auth/styles"
import LoadingIndicator from "src/components/LoadingIndicator"

// import { Container } from './styles';

const Trainers: React.FC = () => {
    const user = useContext(AuthContext)
    const navigate = useNavigate()

    const { data, isLoading } = useQuery(
        "trainers",
        () => {
            return api
                .post("trainee/trainers", { id: user.user?.id })
                .then((res) => res.data as Trainer[])
                .catch(() => [] as Trainer[])
        },
        {
            staleTime: 60 * 1000,
            cacheTime: 3600 * 1000,
        }
    )

    function setTrainer(t: Trainer) {
        user.setTrainer(t)
        navigate("/home")
    }

    if (isLoading || !data)
        return (
            <Wrapper className="page">
                <FhLogoInline style={{ margin: 40 }} />

                <LoadingIndicator isLoading={isLoading}>
                    <button>Sair</button>
                </LoadingIndicator>
            </Wrapper>
        )
    else
        return (
            <Wrapper className="page">
                <FhLogoInline style={{ margin: 40 }} />

                <h4>Selecione o Professor:</h4>
                <Listing>
                    {data.length > 0 ? (
                        data.map((element) => (
                            <ListItem
                                key={element.name}
                                onClick={() => setTrainer(element)}
                            >
                                <Avatar radius={48} />
                                <div
                                    style={{
                                        flexDirection: "column",
                                        flex: 1,
                                        paddingLeft: 15,
                                    }}
                                >
                                    <h4>{element.name}</h4>
                                    <p>{element.name_pj}</p>
                                </div>
                                <BsChevronRight size={24} color="var(--font)" />
                            </ListItem>
                        ))
                    ) : (
                        <div
                            style={{
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            <FiUserX size={48} color="#fff" />
                            <p style={{ textAlign: "center", marginTop: 50 }}>
                                Você não possui vinculo com um professor!
                            </p>
                            <span style={{ margin: 10 }} />
                            <p style={{ textAlign: "center" }}>
                                Entre contato com o administrador dos seus
                                treinos, e revise o vínculo.
                            </p>
                            <span style={{ margin: 10 }} />
                            <Hyperlink onClick={user.Logout}>
                                VOLTAR AO LOGIN
                            </Hyperlink>
                        </div>
                    )}
                </Listing>
            </Wrapper>
        )
}

export default Trainers
