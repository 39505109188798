import React, { useContext } from "react"
import { BsFillBarChartFill } from "react-icons/bs"
import { PiBellBold, PiChatFill, PiFireFill, PiMedalFill } from "react-icons/pi"
import ButtonIcon from "../../../../components/button-icon"
import {
    Buttons,
    DashboardBackground,
    Header,
    Logout,
    NotificationEmpty,
    Notifications,
    PersonalInfo,
    UserInfo,
} from "./styles"
import Avatar from "../../../../components/avatar"
import { useNavigate } from "react-router-dom"
import { Column } from "../../../../assets/global-styles"
import AppHeader from "../../../../components/app-header"
import AuthContext from "src/contexts/auth"
import { MdLogout } from "react-icons/md"
import { TbSwitch } from "react-icons/tb"
import FixedDiv from "src/components/FixedFooter"
import StartedShortcut from "src/components/StartedShortcut"
// import { Container } from './styles';

const Dashboard: React.FC = () => {
    const navigate = useNavigate()

    const context = useContext(AuthContext)

    return (
        <DashboardBackground className={"page"}>
            <AppHeader>
                <Logout onClick={context.Logout}>
                    <span />
                    <MdLogout size={24} color="var(--font)" />
                </Logout>
            </AppHeader>

            <Column style={{ flex: 1, padding: 10 }}>
                <Header>
                    <UserInfo>
                        <Avatar radius={70} />
                        <p>{context.user?.name}</p>
                    </UserInfo>
                </Header>

                <PersonalInfo onClick={() => navigate("/")}>
                    <Avatar radius={45} />
                    <div
                        style={{
                            flex: 1,
                            flexDirection: "column",
                            padding: 15,
                        }}
                    >
                        <h4 style={{ maxWidth: "50vw" }}>
                            {context.trainer?.name}
                        </h4>
                        <h5>{context.trainer?.name_pj}</h5>
                    </div>
                    <div>
                        <p>ALTERAR</p>
                        <TbSwitch size={16} color="var(--font)" />
                    </div>
                </PersonalInfo>

                <Notifications>
                    <NotificationEmpty>
                        <PiBellBold />
                        <p>Você não possui notificações.</p>
                    </NotificationEmpty>

                    {/*<NotificationItem expand>
                        <Row style={{ justifyContent: "space-between" }}>
                            <h4>Notificação</h4>
                            <PiBellFill style={{ marginRight: 5 }} />
                        </Row>

                        <p>Júlio mandou um f1</p>
                    </NotificationItem>*/}
                </Notifications>

                <StartedShortcut />

                <FixedDiv>
                    <div
                        style={{
                            flex: 1,
                            padding: 20,
                            flexDirection: "column",
                        }}
                    >
                        <Buttons>
                            <ButtonIcon
                                title="Planos de Treinos"
                                icon={PiFireFill}
                                onPress={() => navigate("/plans")}
                            />
                            <span style={{ margin: 4 }} />
                            <ButtonIcon
                                title="Avaliações"
                                icon={BsFillBarChartFill}
                                onPress={() => navigate("/assessments")}
                            />
                        </Buttons>
                        <span style={{ margin: 4 }} />
                        <Buttons>
                            <ButtonIcon
                                title="Chat Professor"
                                icon={PiChatFill}
                                onPress={() => alert("Indisponível")}
                            />
                            <span style={{ margin: 4 }} />
                            <ButtonIcon
                                title="Recordes"
                                icon={PiMedalFill}
                                onPress={() => alert("Indisponível")}
                            />
                        </Buttons>
                    </div>
                </FixedDiv>
            </Column>
        </DashboardBackground>
    )
}

export default Dashboard
