import { styled } from "styled-components"

export const Header = styled.h3`
    font-size: 18px;
`

export const Item = styled.li`
    display: flex;
    justify-content: space-between;

    align-items: center;

    height: 30px;

    padding-inline: 5px;
    border-radius: 8px;
`

export const Key = styled.p`
    font-size: 12px;
`

export const ItemSection = styled.ul`
    flex: 1;
    flex-direction: column;

    padding: 10px;
    margin-block: 5px;

    li:nth-child(even) {
        background-color: #444;
    }
`

export const Value = styled.p`
    font-size: 12px;
    align-items: center;
`
