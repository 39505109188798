import moment from "moment"

export function ValidatePassword(senha?: string) {
    if (!senha || senha === null) return false

    const regexNumero = /\d/ // Expressão regular para verificar se há pelo menos um número
    const tamanhoMinimo = 8 // Tamanho mínimo da senha

    if (senha.length < tamanhoMinimo) {
        return false // A senha não possui pelo menos 8 caracteres
    }

    if (!regexNumero.test(senha)) {
        return false // A senha não contém pelo menos um número
    }

    return true // A senha atende aos requisitos
}

export function ValidateCPF(val: string) {
    if (!val || val === "") return true

    function validaCpf(cpf: string) {
        let soma = 0
        let resto

        if (cpf === "00000000000") return false

        for (let i = 1; i <= 9; i++) {
            soma += parseInt(cpf.substring(i - 1, i)) * (11 - i)
        }

        resto = (soma * 10) % 11

        if (resto === 10 || resto === 11) resto = 0

        if (resto !== parseInt(cpf.substring(9, 10))) return false

        soma = 0

        for (let i = 1; i <= 10; i++) {
            soma += parseInt(cpf.substring(i - 1, i)) * (12 - i)
        }

        resto = (soma * 10) % 11

        if (resto === 10 || resto === 11) resto = 0

        if (resto !== parseInt(cpf.substring(10, 11))) return false

        return true
    }

    function validaCnpj(cnpj: string) {
        if (cnpj === "00000000000000") return false

        let tamanho = cnpj.length - 2
        let numeros = cnpj.substring(0, tamanho)
        const digitos = cnpj.substring(tamanho)
        let soma = 0
        let pos = tamanho - 7

        for (let i = tamanho; i >= 1; i--) {
            soma += parseInt(numeros.charAt(tamanho - i)) * pos--
            if (pos < 2) pos = 9
        }

        let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)

        if (resultado !== parseInt(digitos.charAt(0))) return false

        tamanho = tamanho + 1
        numeros = cnpj.substring(0, tamanho)
        soma = 0
        pos = tamanho - 7

        for (let i = tamanho; i >= 1; i--) {
            soma += parseInt(numeros.charAt(tamanho - i)) * pos--
            if (pos < 2) pos = 9
        }

        resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)

        if (resultado !== parseInt(digitos.charAt(1))) return false

        return true
    }

    const valSemCaracteresEspeciais = val.replace(/\D/g, "") // Remove todos os caracteres não-dígitos

    if (valSemCaracteresEspeciais.length === 11) {
        return validaCpf(valSemCaracteresEspeciais)
    } else if (valSemCaracteresEspeciais.length === 14) {
        return validaCnpj(valSemCaracteresEspeciais)
    } else {
        return false
    }
}

export function ValidateDate(data: string, format: string) {
    return moment(data, format)
}
