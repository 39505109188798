import _compendium from "./json/compendium.json"
import _methods from "./json/methods.json"
import _series from "./json/series.json"
import _reps from "./json/reps.json"
import _cadency from "./json/cadency.json"
import _interval from "./json/interval.json"

import { FieldType } from "./interfaces/exercise"

export interface Data {
    title: string
    description: string
    field_type: FieldType
    mask: string
    spliter?: string
}

export interface Cell {
    header: string
    data: Array<Data>
}

export const getData = (name: string) => {
    switch (name) {
        default:
            return series
        case "method":
            return method
        case "series":
            return series
        case "reps":
            return reps
        case "cadency":
            return cadency
        case "interval":
            return interval
    }
}

export const compendium = _compendium
export const method = _methods as Cell
export const series = _series as Cell
export const reps = _reps as Cell
export const cadency = _cadency as Cell
export const interval = _interval as Cell
