import React, { InputHTMLAttributes, useEffect, useState } from "react"
import { IconType } from "react-icons"
import { Area } from "./styles"
import { ValidatedField } from "src/modules/fithub-shared/interfaces/form"
import { FiEye, FiEyeOff } from "react-icons/fi"

// import { Container } from './styles';

interface CustomButton extends InputHTMLAttributes<HTMLInputElement> {
    error?: boolean
    align?:
        | "start"
        | "end"
        | "left"
        | "right"
        | "center"
        | "justify"
        | "match-parent"
    icon?: IconType
    height?: number
    width?: number | string
    selectOnFocus?: boolean
    revealPassword?: boolean
    maxLength?: number
    minLength?: number
    iconColor?: string
    radius?: string
    autofocus?: boolean
    backgroundColor?: string
    onChangeText?: (value: string) => void
    onEndText?: (t: string) => void
    onChangeValidate?: (value: ValidatedField) => void
}

const Inputfield: React.FC<CustomButton> = (props) => {
    const [error, setError] = useState(props.error)
    const [reveal, setReveal] = useState(false)

    useEffect(() => {
        console.log("error")
    }, [props.error])

    const Ico = () => {
        if (props.icon === undefined) {
            return <></>
        } else {
            return (
                <div style={{ marginInline: 5 }}>
                    <props.icon color={"var(--font)"} size={18} />
                </div>
            )
        }
    }

    function _OnChange(e: React.ChangeEvent<HTMLInputElement>) {
        if (props.onChange) props.onChange(e)
        if (props.onChangeText) props.onChangeText(e.target.value)
        if (props.onChangeValidate)
            props.onChangeValidate({
                value: e.target.value,
                valid: !error as boolean,
            })
    }

    function _OnBlur(e: React.FocusEvent<HTMLInputElement, Element>) {
        if (props.minLength) {
            setError(
                e.target.value.length > 0 &&
                    e.target.value.length < props.minLength
            )
        }

        if (props.onEndText) props.onEndText(e.target.value)
    }

    function _OnFocus(e: React.FocusEvent<HTMLInputElement, Element>) {
        if (props.selectOnFocus) e.target.select()
    }

    const Reveal = () => {
        setReveal(!reveal)
    }

    const RevealType = () => {
        if (props.type === "password") {
            return reveal ? "reveal" : "password"
        } else {
            return props.type
        }
    }

    return (
        <Area
            radius={props.radius}
            style={{
                flexDirection: "row",
                width: props.width,
                height: props.height,
                textAlign: props.align,
                paddingLeft: props.icon ? 0 : 5,

                borderColor: error ? "red" : "var(--border)",
            }}
        >
            <Ico />
            <input
                {...props}
                type={RevealType()}
                tabIndex={props.tabIndex}
                onChange={_OnChange}
                onBlur={_OnBlur}
                onFocus={_OnFocus}
            />
            {props.type === "password" && (
                <button
                    style={{ padding: 5, borderRadius: 25, cursor: "pointer" }}
                    onClick={Reveal}
                >
                    {reveal ? <FiEyeOff /> : <FiEye />}
                </button>
            )}
        </Area>
    )
}

export default Inputfield
