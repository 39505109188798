import styled from "styled-components"

export const Wrapper = styled.div`
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    span {
        margin: 5px;
    }
`

export const Form = styled.form`
    width: 100%;
    max-width: 250px;
    flex-direction: column;
    align-items: center;

    > div {
        width: 100%;
    }

    > button:disabled {
        flex-direction: row;
        align-items: center;
        background-color: grey;

        padding: 7px;
        border-radius: 25px;
        font-size: 14px;

        cursor: not-allowed;

        :hover {
            all: unset;
        }
    }

    > button {
        flex-direction: row;
        align-items: center;
        background-color: red;

        padding: 7px;
        border-radius: 25px;
        font-size: 14px;

        p {
            text-transform: uppercase;
            font-weight: bold;
        }
    }
`
