import jsonPerimetry from "./perimetry.json"

export interface PerimetryList {
    rightArmRelaxed?: number
    rightArmContracted?: number
    leftArmRelaxed?: number
    leftArmContracted?: number
    shoulderToShoulder?: number
    thorax?: number
    waist?: number
    abdomen?: number
    hip?: number
    proximalThighLeft?: number
    middleThighLeft?: number
    distalThighLeft?: number
    calfLeft?: number
    proximalThighRight?: number
    middleThighRight?: number
    distalThighRight?: number
    calfRight?: number
}

export const getPerimKey = (str: string) => str as PerimetryListKeys

export const perimKeys = jsonPerimetry as {
    name: PerimetryListKeys
    pt: string
}[]

export type PerimetryListKeys = keyof PerimetryList
