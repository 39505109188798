import React from "react"
import { Apex, ChartWrapper } from "./styles"
import { MdCircle } from "react-icons/md"
import { GraphData } from "src/modules/fithub-shared/anthropometry/antrophometry"

interface DonutChartProps {
    data: GraphData
}

const DonutChart: React.FC<DonutChartProps> = ({ data }) => {
    const colors4: string[] = ["#FF004F", "#00C372", "#175ddf"]
    const labels4 = ["Gordura (Kg)", "Residual (Kg)", "Músculos e Ossos (Kg)"]

    const _data: Array<number> = [data.fat, data.wastes, data.muscles].map(
        (e) => (e = parseFloat(e.toFixed(2)))
    )

    if (data.bones) {
        _data.push(parseFloat(data.bones.toFixed(2)))
        labels4.push("Ossos (Kg)")
        labels4[2] = "Músculos (Kg)"
        colors4[2] = "#7625F5"
        colors4.push("#FFB800")
    } else {
        labels4[2] = "Músculos e Ossos (Kg)"
    }

    return (
        <ChartWrapper className="donut-chart">
            <Apex
                type="donut"
                height={"250px"}
                series={_data}
                labels={labels4}
                options={{
                    chart: { type: "donut" },
                    colors: colors4,
                    legend: {
                        position: "top",
                    },
                    labels: labels4,
                    plotOptions: {
                        pie: {
                            donut: {
                                size: "20%", // Ajuste este valor para alterar o raio interno
                            },
                        },
                    },
                }}
            />
            <span />

            <div
                style={{
                    flexDirection: "column",
                    padding: 5,
                    width: "100%",
                }}
            >
                {labels4.map((element, index) => (
                    <div
                        key={index + "label"}
                        style={{
                            alignItems: "center",

                            justifyContent: "space-between",
                            padding: 2,
                            fontSize: 12,
                        }}
                    >
                        <div>
                            <MdCircle color={colors4[index]} size={12} />
                            <span style={{ marginLeft: 5 }} />
                            <p>{element.toUpperCase()}</p>
                        </div>

                        <span style={{ marginLeft: 5 }} />
                        <p style={{ fontWeight: "bold" }}>
                            {_data[index].toFixed(2)} Kg
                        </p>
                    </div>
                ))}
            </div>

            <h4
                style={{
                    backgroundColor: "#1d1d1d",
                    border: "1px solid #444",
                    padding: 10,
                    fontSize: 12,
                    borderRadius: 50,
                }}
            >
                {`PESO TOTAL: ${
                    data.fat + data.muscles + data.wastes + (data.bones ?? 0)
                } Kg`}
            </h4>
        </ChartWrapper>
    )
}

export default DonutChart
