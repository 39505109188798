import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"

// import { Container } from './styles';

const NotFound: React.FC<{ redirect?: string }> = ({ redirect }) => {
    const navigation = useNavigate()

    useEffect(() => {
        if (redirect) navigation(redirect)
    }, [])

    return (
        <div
            style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
            }}
        >
            <p style={{ fontSize: 24, color: "#fff" }}>PAGINA NÃO ENCONTRADA</p>
            <button
                style={{
                    backgroundColor: "#444",
                    padding: 8,
                    width: 150,
                    marginTop: 15,

                    borderRadius: 5,
                }}
                onClick={() => navigation(-1)}
            >
                Voltar
            </button>
        </div>
    )
}

export default NotFound
