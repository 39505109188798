import styled from "styled-components"

export const Wrapper = styled.div`
    flex: 1;
    width: 100%;
    z-index: 1;
`

export const AssessmentList = styled.ul`
    flex-direction: column;
    padding: 10px;
    width: 100%;
`

export const AssessmentItem = styled.li`
    flex-direction: column;
    background-color: #222;
    color: var(--font);
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #444;
    margin-bottom: 10px;
`
