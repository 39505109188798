import React from "react"
import { Row } from "../../../../../../assets/global-styles"

import { Panel, ScrollView } from "../../styles"
import { compendium } from "../../../../../fithub-shared/data"
import { PiClock } from "react-icons/pi"
import { Aerobic } from "../../../../../fithub-shared/interfaces/workout"
import EmptyArray from "src/components/EmptyArray"
import { styled } from "styled-components"

// import { Container } from './styles';

export const ObsView = styled.div`
    flex-direction: column;
    padding: 10px;

    p {
        flex-direction: column;
        font-size: 12px;
        white-space: pre-wrap;
    }

    h4 {
        text-transform: uppercase;
        padding-bottom: 5px;
    }

    border: 1px solid var(--backgroundLight);
    background-color: #ddd1;
    border-radius: 5px;
`

const AerobicView: React.FC<{ content: Aerobic; obs: string }> = (props) => {
    const { exercises } = props.content

    return (
        <>
            <ObsView>
                <h4>Observações Gerais:</h4>
                <p>{props.obs}</p>
            </ObsView>
            <br />
            {exercises.length > 0 ? (
                <ScrollView>
                    {exercises.map((e, index) => {
                        return (
                            <Panel
                                style={{
                                    padding: 10,
                                    border: "1px solid #444",
                                    marginBottom: 10,
                                }}
                                key={index}
                            >
                                <Row
                                    style={{
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <h4>Exercício Aeróbico</h4>
                                    <Row
                                        style={{
                                            alignItems: "center",
                                        }}
                                    >
                                        <PiClock style={{ marginRight: 5 }} />
                                        <p>{e.duration} minutos</p>
                                    </Row>
                                </Row>
                                <br />
                                <p style={{ padding: 10 }}>
                                    {compendium[e.intensity].description}
                                </p>
                            </Panel>
                        )
                    })}
                </ScrollView>
            ) : (
                <EmptyArray itemTitle="Exercícios" />
            )}
        </>
    )
}

export default AerobicView
