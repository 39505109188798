import jsonBones from "./bones.json"

export interface BonesList {
    femur?: number
    humerus?: number
    fist?: number
}

export const getBonesKey = (str: string) => str as BonesListKeys

export const bonesKeys = jsonBones as {
    name: BonesListKeys
    pt: string
}[]

export type BonesListKeys = keyof BonesList
