import React from "react"
import { styled } from "styled-components"

const AvatarCircle = styled.div<{ radius: number }>`
    height: ${(p) => p && p.radius + "px"};
    width: ${(p) => p && p.radius + "px"};
    border-radius: ${(p) => p && p.radius + "px"};
    aspect-ratio: 1;
    img {
        width: 100%;
        height: 100%;
    }
`

const Avatar: React.FC<{ radius: number }> = (props) => {
    return (
        <AvatarCircle radius={props.radius}>
            <img
                src={require("../../assets/images/userd-demo-pic.png")}
                alt={""}
            />
        </AvatarCircle>
    )
}

export default Avatar
