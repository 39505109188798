import { styled } from "styled-components"

export const SerieBox = styled.div<{ disabled?: boolean }>`
    justify-content: space-between;
    ${(p) => p.disabled && "opacity: 0.5;"}

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    h4 {
        font-size: 12px;
        color: darkgray;
    }

    div > {
        width: calc(100% / 3);

        margin-bottom: 2.5px;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
`

export const DataColumn = styled.div`
    h4 {
        font-size: 12px;
    }

    padding-inline: 2.5px;
    width: calc(100% / 3);
    align-items: center;
    justify-content: center;

    input {
        width: 80%;
        height: 90%;
        padding: 5px;
        text-align: center;
        font-weight: bold;
        font-size: 14px;
        outline: none;

        border-radius: 5px;

        background-color: var(--foreground);

        color: var(--font);
    }

    input::placeholder {
        font-weight: normal;
        opacity: 0.5;
        font-style: italic;
        color: var(--font);
    }
`

export const FeedbackArea = styled.div`
    flex-direction: column;
    align-items: center;
    width: calc(100vw - 80px);
    max-height: calc(100vh - 100px);
    max-width: 400px;

    .title {
        align-items: center;
        padding-bottom: 10px;
        h4 {
            margin-right: 10px;
        }
    }

    textarea {
        resize: vertical;
        width: 100%;

        background-color: #fff1;
        border-radius: 10px;
        padding: 10px;
        min-height: 200px;
        margin-bottom: 10px;
    }

    button {
        background-color: var(--primary);
        font-weight: bold;
        padding: 10px;
        cursor: pointer;
        border-radius: 8px;
    }
`
