import React from "react"
import { TbFileXFilled } from "react-icons/tb"

// import { Container } from './styles';

const EmptyArray: React.FC<{ itemTitle: string }> = ({ itemTitle }) => {
    return (
        <div
            style={{
                width: "100%",
                flex: 1,
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            <TbFileXFilled size={48} color="var(--font)" />
            <p
                style={{
                    fontSize: 18,
                    textAlign: "center",
                    marginTop: 10,
                }}
            >
                Você não possui
                <br />
                {itemTitle} ativos!
            </p>
        </div>
    )
}

export default EmptyArray
