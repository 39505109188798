import React from "react"
import {
    Aerobic,
    Crossfit,
    Heating,
    Weight,
} from "../../../../../modules/fithub-shared/interfaces/workout"
import AppHeader from "../../../../../components/app-header"
import { PiFireBold } from "react-icons/pi"
import CrossfitView from "./Crossfit"
import AerobicView from "./Aerobic"
import WeightView from "./Weight/View"

import { useQuery } from "react-query"
import { GetParameter } from "src/assets/functions"
import { fetchWorkout } from ".."
import { Wrapper } from "../styles"
import { FiAlertCircle } from "react-icons/fi"
import LoadingIndicator from "src/components/LoadingIndicator"

// import { Container } from './styles';

const WorkoutView: React.FC = () => {
    const plan = GetParameter("pid")
    const workout = GetParameter("wid")

    const { data, isLoading } = useQuery(["workout", workout], () =>
        fetchWorkout(plan, workout)
    )

    const WorkoutContent = () => {
        if (!data || !data.type) {
            return (
                <div
                    style={{
                        flex: 1,
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <FiAlertCircle color="white" />
                    <p>{(data as any).message}</p>
                    <button />
                </div>
            )
        }

        switch (data.type) {
            default:
                return <LoadingIndicator isLoading={isLoading} />
            case 1:
                return <WeightView content={data.item as Weight} />
            case 2:
                return (
                    <AerobicView
                        content={data.item as Aerobic}
                        obs={data.obs}
                    />
                )
            case 3:
                return <CrossfitView content={data.item as Crossfit} />
            case 4:
                return <CrossfitView content={data.item as Heating} />
        }
    }

    return (
        <Wrapper className={"page"}>
            <AppHeader
                goBack
                title={data ? data.title : "TREINO"}
                icon={PiFireBold}
            />

            {isLoading || !data ? (
                <>
                    <LoadingIndicator isLoading={isLoading} />
                </>
            ) : (
                <>
                    <WorkoutContent />
                </>
            )}
        </Wrapper>
    )
}

export default WorkoutView
