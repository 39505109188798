import styled from "styled-components"
import { createGlobalStyle } from "styled-components"

const GlobalStyles = createGlobalStyle`

    :root {
        .dark{
            --primary: #ff0022;
            --primaryDark: #870012;
            --font: #fff;
            --background: #1d1d1d;
            --backgroundLight: #333;
            --border: #444;
            --panel: #222;
            --shadow: #fff2;
        }

        .light{
            --primary: #ff0022;
            --primaryDark: #870012;
            --font: #000;
            --background: #fff;
            --backgroundLight: #ddd;
            --border: #ccc;
            --panel: #eee;
            --shadow: #00000044;
        }
    }

    ::-webkit-scrollbar {
        width: 6px; /* Largura da barra de rolagem */
        height: 6px; /* Altura da barra de rolagem */
    }

    /* Handler (puxador) da barra de rolagem */
    ::-webkit-scrollbar-thumb {
        background-color: var(--backgroundLight); /* Cor do handler (puxador) */
        border-radius: 0px; /* Borda arredondada do handler */
    }

    /* Fundo da barra de rolagem */
    ::-webkit-scrollbar-track {
        background-color: transparent; /* Fundo transparente */
    }

    *{
        box-sizing: border-box;
        padding:0px;
        margin:0px;
        -webkit-tap-highlight-color: transparent;

    } 

    * {
        font-family: 'Exo 2', sans-serif;

    }

    p, h1, h2, h3, h4, h5, h6, img{
        user-select: none;
        font-family: 'Exo 2', sans-serif;
        pointer-events: none;
        color:var(--font);
    }

    @media only screen and (orientation: landscape) and (hover: none) {
        body:focus-within,
        body:focus-within .an-absolutely-positioned-input-ancestor {
            max-height: 53vh;
            overflow: auto;
        }
    }

    @keyframes slideFromBottom {
        0% {
            opacity: 0;
            //transform: translateY(10%);
        }
        100% {
            opacity: 1;
            //transform: translateY(0);
        }
    }

    .spaced{
        justify-content: space-between;
    }

    .page{
        z-index: 1;
        animation: 0.15s ease-in-out 0s 1 slideFromBottom;
    }

    .blur{
        filter: blur(2px);
    }

    .notblur{
        filter: none;
    }

    .shadow{
        -webkit-box-shadow: 0px 1px 4px 0px var(--shadow);
        box-shadow: 0px 1px 4px 0px var(--shadow);
    }

    button{
        outline: none;
        border: none;

        color: var(--font);
        background-color: transparent;
        transition: background-color .2s ;
        transition: opacity .1s ;

        cursor: pointer;
    }

    button:hover{
        opacity: 0.8;
    }

    button:active{
        opacity: 0.5;
    }

`

export const Column = styled.div`
    flex-direction: column;
`

export const Row = styled.div`
    flex-direction: row;
`

export const MobileContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: center;

    overflow: hidden;

    background-color: #333;
`

export const MobileBorders = styled.div`
    position: relative;
    min-height: 100vh;
    width: 100vw;

    display: flex;

    flex-direction: column;
    max-width: 800px;
    max-height: 1000px;

    -webkit-overflow-scrolling: touch;

    @media (min-width: 768px) {
        border-radius: 10px;
        overflow: hidden;
    }

    @media (max-width: 768px) {
        max-width: unset;
        max-height: unset;
        overflow-x: hidden;
        overflow-y: auto;
    }

    background-color: var(--background);

    * {
        display: flex;
    }
`

export default GlobalStyles
