import { WeightExercise } from "@fithub-shared/interfaces/exercise"
import React, { useEffect, useState } from "react"

// import { Container } from './styles';

const BisetComponent: React.FC<{ exercise: WeightExercise }> = ({
    exercise,
}) => {
    const [biset, setBiset] = useState("")

    useEffect(() => {
        switch (exercise.values.method.option) {
            default:
                setBiset("-")
                break
            case 16:
                setBiset("Biset 1")
                break
            case 17:
                setBiset("Biset 2")
                break
            case 18:
                setBiset("Triset")
                break
            case 19:
                setBiset("Triset")
                break
        }
    }, [exercise])

    return (
        <>
            <div
                style={{
                    width: "100%",
                    justifyContent: "center",
                    maxHeight: 30,
                    marginBottom: 5,

                    top: 35,
                    pointerEvents: "none",
                }}
            >
                <p
                    style={{
                        padding: 3,
                        borderRadius: 5,
                        height: 20,
                        backgroundColor:
                            biset !== "-" ? "var(--primary)" : "transparent",
                    }}
                >
                    {biset}
                </p>
            </div>
        </>
    )
}

export default BisetComponent
