import { styled } from "styled-components"

export const Wrapper = styled.div`
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    animation: 0.15s ease-in-out 0s 1 slideFromBottom;

    ul {
        flex-direction: column;
    }
`

export const PlanItemHeader = styled.div`
    width: 100%;
    align-items: center;
    justify-content: space-between;
    background-color: var(--backgroundLight);
    padding-block: 10px;
    border-radius: 5px;
    padding-inline: 10px;
`

export const PlanButton = styled.button`
    flex-direction: column;
    border-radius: 5px;
    background-color: var(--background);
    margin-bottom: 10px;
    height: 90px;
    padding: 5px;
    border: 1px solid #444;

    h4 {
        font-size: 14px;
    }

    justify-content: space-between;
`
