const emailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

export const ValidateEmail = (email: string | undefined) =>
    emailFormat.test(email ? email : "")

export const ValidatePass = (senha: string) => {
    const requisitos: string[] = []
    let valido = true

    if (senha.length < 8) {
        requisitos.push("Mínimo 8 caracteres")
        valido = false
    }
    if (!senha.match(/[0-9!@#$%^&*(),.?":{}|<>]/)) {
        requisitos.push("Precisa conter um número ou símbolo")
        valido = false
    }
    if (!senha.match(/[A-Z]/)) {
        requisitos.push("Precisa de uma letra maiúscula")
        valido = false
    }

    return {
        valido,
        mensagem: requisitos,
    }
}
