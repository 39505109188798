import React from "react"

// import { Container } from './styles';

const SvgTop: React.FC = () => {
    return (
        <svg
            style={{
                position: "absolute",
                zIndex: 0,
                fill: "var(--backgroundLight)",
            }}
            viewBox="0 0 500 170"
            xmlns="http://www.w3.org/2000/svg"
        >
            <polygon points="0 0 342.925 166.85 500.55 0" />
        </svg>
    )
}

export default SvgTop
