import React, { useState } from "react"
import { Input, Wrapper } from "./styles"

// import { Container } from './styles';
function CPFFormat(number: string) {
    return number.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4")
}

function BRBirthDateFormat(dataOriginal: string) {
    // Verifica se a dataOriginal está no formato correto (ddmmaaaa)
    const regexData = /^(\d{2})(\d{2})(\d{4})$/

    return dataOriginal.replace(regexData, "$1/$2/$3")
}

const Login: React.FC = () => {
    const [email, setEmail] = useState("")
    const [pass, setPass] = useState("")

    function onChangeEmail(e: React.ChangeEvent<HTMLInputElement>) {
        setEmail(e.target.value)
    }

    function onChangePass(e: React.ChangeEvent<HTMLInputElement>) {
        setPass(e.target.value)
    }

    return (
        <Wrapper>
            <Input
                placeholder="E-mail"
                onChange={onChangeEmail}
                value={email}
                maxLength={14}
            />
            <Input
                placeholder="Senha"
                onChange={onChangePass}
                value={pass}
                maxLength={10}
            />

            <button>Entrar</button>
        </Wrapper>
    )
}

export default Login
