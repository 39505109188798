import React from "react"

// import { Container } from './styles';

const FhLogo: React.FC = () => {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="100%"
            height="80px"
            viewBox="0 0 512 250"
            enableBackground="new 0 0 512 250"
            style={{ fill: "var(--font)" }}
        >
            <g>
                <rect x="174.277" y="6.073" width="125.15" height="29.995" />
                <polygon
                    points="203.755,75.371 339.248,75.371 339.248,6.073 308.736,6.073 308.736,45.376 203.755,45.376 174.277,45.376 
		174.277,114.157 203.755,114.157 	"
                />
                <rect x="214.098" y="84.162" width="125.15" height="29.995" />
                <polygon
                    points="14.607,177.637 5.169,177.637 5.169,242.93 14.607,242.93 14.607,215.647 55.979,215.646 55.979,206.856 
		14.607,206.857 14.607,186.429 64.383,186.428 64.383,177.637 14.607,177.638 	"
                />
                <rect x="97.179" y="177.637" width="9.438" height="65.293" />
                <polygon
                    points="139.898,186.752 167.415,186.752 167.415,242.93 176.853,242.93 176.853,186.752 204.37,186.752 204.37,177.637 
		139.898,177.637 	"
                />
                <path
                    d="M507.589,203.915V191.6h-0.013c0,0,0.432-7.237-3.706-11.029c-4.137-3.792-8.791-4.484-8.791-4.484h-18.962h-21.548
		h-16.893v66.844h16.893h21.548h18.962c0,0,4.654-0.692,8.791-4.484c4.138-3.792,3.706-11.029,3.706-11.029v-0.779h0.013v-12.314
		c0-1.948-0.739-5.204-4.695-5.204C506.85,209.118,507.589,205.863,507.589,203.915z M492.583,217.199v9.223l-0.904,0.994h-15.562
		h-21.548v-11.769h35.428h1.034c0,0,0.771-0.007,1.21,0.433S492.583,217.199,492.583,217.199z M492.241,202.157
		c-0.439,0.439-1.21,0.433-1.21,0.433h-1.034h-35.428V191.6h21.548h15.26l1.206,1.251v8.188
		C492.583,201.038,492.681,201.718,492.241,202.157z"
                />
                <polygon
                    points="286.586,200.779 254.534,200.779 254.534,176.732 237.64,176.732 237.64,242.93 254.534,242.93 254.534,217.587 
		286.586,217.587 286.586,242.93 303.478,242.93 303.478,176.732 286.586,176.732 	"
                />
                <path
                    d="M405.097,176.732h-16.979v50.684h-34.906v-50.684h-16.979v50.684c0,0-0.185,6.984,3.705,11.029
		c4.313,4.484,8.792,4.484,8.792,4.484h43.856c0,0,5.075-0.232,8.828-4.719c3.812-4.557,3.67-10.795,3.67-10.795h0.013V176.732z"
                />
            </g>
        </svg>
    )
}

export default FhLogo
