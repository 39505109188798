import jsonFolds from "./skinfolds.json"

export interface SkinFoldsList {
    triceps?: number
    subscapular?: number
    biceps?: number
    breastplate?: number
    middleAxillary?: number
    supraIliac?: number
    abdominal?: number
    middleThigh?: number
    calf?: number
}

export const getFoldsKey = (str: string) => str as SkinFoldsListKeys

export const foldsKeys = jsonFolds as {
    name: SkinFoldsListKeys
    pt: string
}[]

export type SkinFoldsListKeys = keyof SkinFoldsList
