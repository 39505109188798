import styled from "styled-components"

interface Props {
    radius?: string
}

export const Area = styled.div<Props>`
    flex-direction: row;

    height: 30px;

    justify-content: center;
    align-items: center;

    border-radius: ${(props) => (props.radius ? props.radius : "5px")};
    border: solid 1.2px #0003;
    background-color: var(--background);

    p {
        all: unset;
        font-size: 14px;
        border: 0;
        outline: 0;
        width: 100%;
        padding-bottom: 1px;
        padding-right: 5px;
        opacity: 0.75;
    }

    input {
        all: unset;
        color: var(--font);
        font-size: 14px;
        border: 0;
        background-color: transparent;
        height: 100%;
        width: 100%;
        padding-bottom: 1px;
    }

    img {
        filter: invert(15%);
        margin: 6px;
        height: 15px;
        width: 15px;
    }
`
