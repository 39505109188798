import { styled } from "styled-components"
import { Column, Row } from "../../../../assets/global-styles"

export const Wrapper = styled.div`
    z-index: 0;
    flex-direction: column;
    overflow: hidden;
    flex: 1;
`

export const ScrollView = styled.div`
    flex-direction: column;
    flex: 1;
    align-items: center;

    padding: 10px;

    p {
        white-space: pre;
        font-size: 12px;
        white-space: pre-wrap;
    }

    h4 {
        font-size: 14px;
    }

    h5 {
        font-size: 12px;

        text-transform: uppercase;
        font-weight: semibold;
    }
`

export const Header = styled(Row)`
    justify-content: space-between;
`

export const Observations = styled(Column)`
    padding: 8px;
    margin-top: 5px;

    background-color: #eee;
    border-radius: 5px;

    background-color: var(--background);
    color: var(--font);

    p {
        white-space: pre;
        font-size: 12px;
        white-space: pre-wrap;
    }

    h4 {
        font-size: 14px;
    }

    h5 {
        font-size: 12px;

        text-transform: uppercase;
        font-weight: semibold;

        margin-bottom: 5px;
        font-weight: bold;
        color: var(--font);
    }
`

export const Button = styled.button`
    flex-shrink: 0;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding-left: 10px;

    height: 60px;
    border-radius: 5px;

    margin-block: 10px;

    background-color: var(--background);
    border: 1px solid var(--border);
`

export const LinkParagraph = styled.div`
    display: block;
    white-space: pre-line;

    a {
        all: unset;
        flex: 0;
        width: fit-content;
        display: inline;
        text-decoration: underline;
        cursor: pointer;
        pointer-events: all;
    }
`

export const Panel = styled.div`
    border-radius: 15px;
    border: 1px solid #444;
    width: 100%;

    padding-bottom: 5px;

    color: var(--font);
    background-color: var(--background);

    iframe {
        border: none;

        border-top-right-radius: 15px;
        border-top-left-radius: 15px;
    }

    p {
        white-space: pre-wrap;
        font-size: 14px;
    }

    a {
    }

    flex-direction: column;
`
