import FhLogo from "src/components/FhLogo"
import { styled } from "styled-components"

export const Wrapper = styled.div`
    * {
        font-family: "Exo 2", sans-serif;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;

    span {
        margin: 5px;
    }
`

export const Logo = styled(FhLogo)``

export const Panel = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 300px;
    width: 100%;
`

export const LoginButton = styled.button`
    background-color: red;

    width: 100px;
    justify-content: space-between;
    padding: 5px;
    padding-inline: 8px;
    border-radius: 25px;
    align-items: center;
`

export const Hyperlink = styled.button`
    padding: 0px;
    margin: 0px;
    text-decoration: underline;
    color: var(--font);
`
