import React from "react"

import ClickableIcon from "../clickable-icon"
import { HiChevronLeft } from "react-icons/hi"
import { useNavigate } from "react-router-dom"
import { IconType } from "react-icons"
import FhLogoInline from "../FhLogoInline"
import FixedDiv from "../FixedFooter"
// import { Container } from './styles';

const AppHeader: React.FC<{
    goBack?: boolean
    onClickGoBack?: () => void
    title?: string | React.ReactNode
    icon?: IconType
    children?: React.ReactNode
}> = (props) => {
    const navigate = useNavigate()

    function GoBack() {
        if (props.goBack) {
            if (!props.onClickGoBack) navigate(-1)
            else props.onClickGoBack()
        }
    }

    return (
        <FixedDiv isBottom={false}>
            <div
                style={{
                    position: "relative",
                    minHeight: 60,
                    flexShrink: 0,
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                {props.goBack && (
                    <button
                        onClick={GoBack}
                        style={{
                            position: "absolute",
                            left: 0,
                            height: "100%",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <ClickableIcon icon={HiChevronLeft} size={48} />
                    </button>
                )}
                {props.title ? (
                    <div
                        style={{
                            fontSize: 16,
                            color: `white`,
                            fontWeight: "bold",
                            textTransform: "uppercase",
                            width: `60%`,
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        {props.icon && (
                            <props.icon size={30} style={{ marginRight: 5 }} />
                        )}
                        <p style={{ textAlign: "center", fontSize: 16 }}>
                            {props.title}
                        </p>
                    </div>
                ) : (
                    <FhLogoInline />
                )}

                {props.children}
            </div>
        </FixedDiv>
    )
}

export default AppHeader
