import styled from "styled-components"

export const Wrapper = styled.div`
    flex: 1;
    flex-direction: column;
    align-items: center;
`

export const Title = styled.h4``

export const Listing = styled.ul`
    flex: 1;
    width: 100%;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    padding: 10px;
`

export const ListItem = styled.li`
    width: 100%;

    align-items: center;

    margin-bottom: 10px;
    padding: 10px;
    background-color: var(--background);

    border: 1px solid var(--border);

    border-radius: 5px;

    h4 {
        font-size: 16px;
    }

    p {
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        opacity: 0.6;
    }

    cursor: pointer;
`
