import api from "src/api"

import _reps from "src/modules/fithub-shared/json/reps.json"
/*import _cadency from "src/modules/fithub-shared/json/cadency.json"
import _interval from "src/modules/fithub-shared/json/interval.json"
import _method from "src/modules/fithub-shared/json/methods.json"*/
import { ExerciseValue } from "@fithub-shared/interfaces/exercise"
import { IconType } from "react-icons"

function extrairIdVideo(url: string) {
    let idVideo = null
    if (url.includes("shorts")) {
        // eslint-disable-next-line
        let matchShorts = url.match(/\/shorts\/([^\?]+)/)
        if (matchShorts && matchShorts[1]) {
            idVideo = matchShorts[1]
        }
    } else {
        let matchWatch = url.match(/\/watch\?v=([^&]+)/)
        if (matchWatch && matchWatch[1]) {
            idVideo = matchWatch[1]
        }
    }

    return idVideo
}

export function YtToIframe(url: string) {
    if (!url) return ""
    const code = extrairIdVideo(url)
    const _url = new URL(`https://youtube.com/embed/${code}`)

    _url.searchParams.append("modestbranding", "1")
    _url.searchParams.append("rel", "0")
    _url.searchParams.append("fs", "0")
    _url.searchParams.append("color", "white")

    return _url.toString()
}

export function GetVideo(linkId: string): Promise<string | undefined> {
    return api
        .post("/exercises/get-video", { id: linkId })
        .then((res) => res.data)
        .catch(() => undefined)
}

export function GetMask(
    data: ExerciseValue,
    obj: any,
    ignoreDesc = false
): string {
    const nums = [...data.value]

    let desc =
        obj.data[data.option].mask === "/"
            ? nums.join(">")
            : obj.data[data.option].mask.replace(/#/g, () => {
                  const numero = nums.shift() // Pega e remove o primeiro elemento do array
                  return numero !== null && numero !== undefined
                      ? numero.toString()
                      : "#" // Retorna o número se houver, senão retorna '#'
              })

    if (!ignoreDesc)
        return desc === ""
            ? obj.data[data.option].description
            : `${desc}\n${obj.data[data.option].description}`
    else return desc
}

export function GetRepsPlaceholder(data: ExerciseValue, index: number) {
    if (_reps.data[data.option].mask === "/") {
        return `${data.value[index]}`
    } else {
        return GetMask(data, _reps, true)
    }
}

export interface CommentInterface {
    title: string
    subtitle?: string
    icon: IconType
}
