import { styled } from "styled-components"

export const Wrapper = styled.button`
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;

    width: 175px;
    height: 60px;

    border-radius: 8px;

    justify-content: space-between;
    align-items: center;
    padding: 15px;
    p {
        font-size: 10px;
    }

    background-color: var(--background);
    border: 1px solid var(--border);
`
