import { styled } from "styled-components"

export const Wrapper = styled.div`
    flex-direction: column;
    flex: 1;
    padding: 10px;

    align-items: center;
    justify-content: center;

    button {
        background-color: red;

        padding: 10px;
        width: 100%;
        border-radius: 5px;
    }
`
export const Input = styled.input`
    padding: 10px;
    width: 100%;
    margin-bottom: 10px;

    font-weight: 500;
`
