import React, { useContext, useState } from "react"
import { MdCake, MdChevronRight, MdFingerprint } from "react-icons/md"
import { Hyperlink, LoginButton, Logo, Panel, Wrapper } from "./styles"
import AuthContext from "src/contexts/auth"
import Inputfield from "src/components/inputfield"
import { useLocation, useNavigate } from "react-router-dom"
// import { Container } from './styles';

const StudentAuth: React.FC = () => {
    const location = useLocation()
    const defaultEmail = location.state ? location.state.email : ""

    const context = useContext(AuthContext)
    const [email, setEmail] = useState(defaultEmail)
    const [pass, setPass] = useState("")
    const [err, setErr] = useState("")

    const nav = useNavigate()

    async function handleLogin() {
        setErr("")
        setErr(await context.Login(email, pass))
    }

    return (
        <Wrapper className="centered">
            <Panel>
                <Logo />
                <span />
                <Inputfield
                    icon={MdFingerprint}
                    width={"100%"}
                    placeholder="E-mail"
                    onChange={(e) => setEmail(e.target.value)}
                    defaultValue={email}
                />
                <span />
                <Inputfield
                    icon={MdCake}
                    width={"100%"}
                    placeholder="Senha"
                    type="password"
                    onChange={(e) => setPass(e.target.value)}
                />
                <span />
                <p style={{ fontSize: 12, color: "red" }}>{err}</p>
                <span />
                <div style={{ justifyContent: "space-between" }}>
                    <LoginButton onClick={handleLogin}>
                        <p>ENTRAR</p>
                        <span />
                        <MdChevronRight />
                    </LoginButton>
                    <Hyperlink
                        onClick={() =>
                            nav("recover", { state: { email: email } })
                        }
                    >
                        Esqueci minha senha
                    </Hyperlink>
                </div>
            </Panel>
        </Wrapper>
    )
}

export default StudentAuth
