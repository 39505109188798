import React from "react"
import { IoPlayOutline, IoStopOutline, IoRefreshOutline } from "react-icons/io5" // Adicionei o ícone de reset
import styled from "styled-components"

interface ControlsProps {
    running: boolean
    currentTime: number // Adicionei a propriedade currentTime
    startTimer: () => void
    pauseTimer: () => void
    resetTimer: () => void
}

const TimerButton = styled.button`
    height: 100%;
    width: 80px;

    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 0px 10px 10px 0px;

    cursor: pointer;
    font-size: 24px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    justify-content: center;
    align-items: center;

    &:focus {
        outline: none;
    }

    transition: background-color 0.05s;

    &:active {
        background-color: #1d1d1d;
    }
`

const Controls: React.FC<ControlsProps> = ({
    running,
    currentTime,
    startTimer,
    pauseTimer,
    resetTimer,
}) => {
    function TimerFunction() {
        if (running) {
            resetTimer()
        } else {
            startTimer()
        }
    }

    // Exibe o botão de reset se o tempo for zero
    if (currentTime <= 0) {
        return (
            <TimerButton onClick={resetTimer}>
                <IoRefreshOutline />
            </TimerButton>
        )
    } else {
        return (
            <TimerButton onClick={TimerFunction}>
                {!running ? <IoPlayOutline /> : <IoStopOutline />}
            </TimerButton>
        )
    }
}

export default Controls
