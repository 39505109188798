import {
    ExerciseExec,
    ExerciseExecCache,
} from "src/modules/fithub-shared/trainee/interfaces"
import { useEffect, useState } from "react"

type RWValues = EventTarget &
    HTMLInputElement & { index: number; exercise: string }

export function useCachedContentN(wid: string): {
    GetReps: (exercise: string, index: number) => number | undefined
    GetWeight: (exercise: string, index: number) => number | undefined
    SetReps: (e: React.ChangeEvent<HTMLInputElement>) => void
    SetWeight: (e: React.ChangeEvent<HTMLInputElement>) => void
    SetFeedback: (exercise: string, value: string) => void
    GetFeedback: (exercise: string) => string
    Cache: ExerciseExecCache
} {
    const [values, setValues] = useState<ExerciseExecCache>({})

    const LoadCached = () => {
        const data = localStorage.getItem(`${wid}@values`)
        if (data) setValues(JSON.parse(data) as ExerciseExecCache)
    }

    useEffect(() => {
        LoadCached()
    }, [wid])

    const GetReps = (exercise: string, index: number) => {
        return values[exercise]?.data?.[index]?.reps ?? undefined
    }

    const GetWeight = (exercise: string, index: number) => {
        return values[exercise]?.data?.[index]?.weight ?? undefined
    }

    const SetReps = (e: React.ChangeEvent<HTMLInputElement>) => {
        const index = parseInt(e.target.getAttribute("data-index") ?? "")
        const exercise = e.target.getAttribute("data-exercise" ?? "")
        const value = e.target.value

        console.log(index, exercise, value)

        if (!exercise || isNaN(index)) return

        const parsedValue = !isNaN(parseInt(value))
            ? parseInt(value)
            : undefined

        const newValues = { ...values }
        if (!newValues[exercise])
            newValues[exercise] = {
                data: [],
                feedback: "",
                wexec: wid,
            }
        if (!newValues[exercise].data[index])
            newValues[exercise].data[index] = { reps: 0 }

        newValues[exercise].data[index].reps = parsedValue

        saveCache(newValues)
    }

    const SetWeight = (e: React.ChangeEvent<HTMLInputElement>) => {
        const index = parseInt(e.target.getAttribute("data-index") ?? "")
        const exercise = e.target.getAttribute("data-exercise" ?? "")
        const value = e.target.value

        console.log(!exercise || isNaN(index))

        if (!exercise || isNaN(index)) return

        const parsedValue = !isNaN(parseInt(value))
            ? parseInt(value)
            : undefined

        const newValues = { ...values }
        if (!newValues[exercise])
            newValues[exercise] = {
                data: [],
                feedback: "",
                wexec: wid,
            }
        if (!newValues[exercise].data[index])
            newValues[exercise].data[index] = { weight: 0 }

        newValues[exercise].data[index].weight = parsedValue

        saveCache(newValues)
    }

    const SetFeedback = (exercise: string, value: string) => {
        const newValues = { ...values }
        if (newValues[exercise]) newValues[exercise].feedback = value
        else
            newValues[exercise] = {
                data: [],
                feedback: value,
                wexec: wid,
            }

        saveCache(newValues)
    }

    const GetFeedback = (exercise: string): string => {
        return values?.[exercise]?.feedback ?? ""
    }

    const saveCache = (newValues: ExerciseExecCache) => {
        localStorage.setItem(`${wid}@values`, JSON.stringify(newValues))
        setValues(newValues)
    }

    return {
        GetReps,
        GetWeight,
        SetReps,
        SetWeight,
        SetFeedback,
        GetFeedback,
        Cache: values,
    }
}

function getConvertedCache<T>(tag: string) {
    const cache = localStorage.getItem(tag)

    if (!cache) return undefined

    return JSON.parse(cache) as T
}

export function convertCachedToExec(wid: string): ExerciseExec[] {
    const cache = getConvertedCache<ExerciseExecCache>(`${wid}@values`)
    if (!cache) return []
    return Object.keys(cache).map((key) => ({
        exercise: key,
        data: cache[key].data,
        feedback: cache[key].feedback,
        wexec: cache[key].wexec,
    }))
}

export function removeOnFinish() {
    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i)

        if (key && key.endsWith("@values")) {
            localStorage.removeItem(key)
            i--
        }
    }

    localStorage.removeItem("current-w")
    localStorage.removeItem("completed")
}

export function removeCachedById(wid: string) {
    localStorage.removeItem(`${wid}@values`)
}

export function setItem(tag: string, value: any) {
    localStorage.setItem(tag, JSON.stringify(value))
}

export function getItem<T>(tag: string) {
    const value = localStorage.getItem(tag)

    if (value !== null) {
        try {
            return JSON.parse(value) as T
        } catch {
            return undefined
        }
    } else {
        return undefined
    }
}

export function removeItem(tag: string) {
    localStorage.removeItem(tag)
}
