import React from "react"

// import { Container } from './styles';

const FetchWrapper: React.FC<{
    children?: React.ReactElement
    isLoading: boolean
}> = ({ children, isLoading }) => {
    return isLoading ? <>Carregando...</> : <>{children}</>
}

export default FetchWrapper
