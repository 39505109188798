import React, { useState } from "react"
import { MdFingerprint } from "react-icons/md"
import {
    Hyperlink,
    LoginButton,
    Logo,
    Panel,
    RecoverInput,
    Wrapper,
} from "./styles"
import Inputfield from "src/components/inputfield"
import { useLocation, useNavigate } from "react-router-dom"
import api from "src/api"
import EmailRecoveryInput from "../../../fithub-shared/components/EmailRecoveryInput"
// import { Container } from './styles';

const RecoverPass: React.FC = () => {
    const location = useLocation()
    const nav = useNavigate()

    const defaultEmail = location.state ? location.state.email : ""

    const [user, setUser] = useState(defaultEmail)
    const [err, setErr] = useState("")

    const [password, setPassword] = useState({
        pass: "",
        confirm: "",
    })

    function HandlePassword(e: React.ChangeEvent<HTMLInputElement>) {
        const { name, value } = e.target
        setPassword({ ...password, [name]: value })

        setErr("")
    }

    const [recovered, setRecovered] = useState(false)

    function ChangeCode(code: string) {
        setErr("")

        if (code.length === 6)
            api.post("recover/verify", { user, code }).then((res) => {
                if (res.status === 200) setErr("Código Incorreto!")
                else setRecovered(true)
            }) //625150
    }

    function CodeRedeem() {
        if (user)
            api.post("recover/password", { user }).then((res) => {
                setErr(res.data)
            })
    }

    function NewPassword() {
        if (password.pass !== password.confirm) {
            alert("As senhas não conferem!")
            return
        }

        api.post("recover/newpass", { user, pass: password.pass }).then(() => {
            alert("Senha alterada com sucesso!")
            nav("/")
        })
    }

    return (
        <Wrapper className="centered">
            <Panel>
                <Logo />

                <span style={{ marginBlock: 10 }} />
                <div
                    style={{
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <RecoverInput
                        placeholder="E-mail"
                        defaultValue={defaultEmail}
                        onChange={(e) => setUser(e.target.value ?? "")}
                        type="email"
                    />
                    <span style={{ margin: 10 }} />
                    <Hyperlink onClick={CodeRedeem}>Enviar Código</Hyperlink>
                </div>
                <span />

                <EmailRecoveryInput
                    disabled={recovered}
                    onChange={ChangeCode}
                />

                {recovered && (
                    <>
                        <span />
                        <Inputfield
                            name="pass"
                            icon={MdFingerprint}
                            type="password"
                            width={"100%"}
                            placeholder="Nova senha"
                            onChange={HandlePassword}
                        />
                        <span />
                        <Inputfield
                            name="confirm"
                            icon={MdFingerprint}
                            type="password"
                            width={"100%"}
                            placeholder="Confirmar nova senha"
                            onChange={HandlePassword}
                        />
                        <span />
                        <LoginButton onClick={NewPassword}>
                            Confirmar Senha
                        </LoginButton>
                    </>
                )}

                <span />
                <p style={{ fontSize: 12, color: "red" }}>{err}</p>
            </Panel>
        </Wrapper>
    )
}

export default RecoverPass
