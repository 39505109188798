import React, { CSSProperties } from "react"

// import { Container } from './styles';

const FhLogoInline: React.FC<{ style?: CSSProperties }> = ({ style }) => {
    return (
        <svg
            viewBox="0 0 480 80"
            xmlns="http://www.w3.org/2000/svg"
            style={{
                fill: "var(--font)",
                maxWidth: 150,
                margin: style?.margin,
            }}
        >
            <g transform="matrix(0.651976, 0, 0, 0.651976, 40.233913, -76.821388)">
                <rect x="-51.787" y="133.763" width="105.178" height="25.208" />
                <polygon points="-27.013 192.002 86.857 192.002 86.857 133.763 61.214 133.763 61.214 166.794 -27.013 166.794 -51.787 166.794 -51.787 224.598 -27.013 224.598" />
                <rect x="-18.321" y="199.39" width="105.178" height="25.208" />
                <polygon points="171.607 147.309 162.169 147.309 162.169 212.602 171.607 212.602 171.607 185.319 212.979 185.318 212.979 176.528 171.607 176.529 171.607 156.101 221.383 156.1 221.383 147.309 171.607 147.31" />
                <rect x="254.179" y="147.309" width="9.438" height="65.293" />
                <polygon points="296.898 156.424 324.415 156.424 324.415 212.602 333.853 212.602 333.853 156.424 361.37 156.424 361.37 147.309 296.898 147.309" />
                <path d="M 664.589 173.587 L 664.589 161.272 L 664.576 161.272 C 664.576 161.272 665.008 154.035 660.87 150.243 C 656.733 146.451 652.079 145.759 652.079 145.759 L 633.117 145.759 L 611.569 145.759 L 594.676 145.759 L 594.676 212.603 L 611.569 212.603 L 633.117 212.603 L 652.079 212.603 C 652.079 212.603 656.733 211.911 660.87 208.119 C 665.008 204.327 664.576 197.09 664.576 197.09 L 664.576 196.311 L 664.589 196.311 L 664.589 183.997 C 664.589 182.049 663.85 178.793 659.894 178.793 C 663.85 178.79 664.589 175.535 664.589 173.587 Z M 649.583 186.871 L 649.583 196.094 L 648.679 197.088 L 633.117 197.088 L 611.569 197.088 L 611.569 185.319 L 646.997 185.319 L 648.031 185.319 C 648.031 185.319 648.802 185.312 649.241 185.752 C 649.68 186.192 649.583 186.871 649.583 186.871 Z M 649.241 171.829 C 648.802 172.268 648.031 172.262 648.031 172.262 L 646.997 172.262 L 611.569 172.262 L 611.569 161.272 L 633.117 161.272 L 648.377 161.272 L 649.583 162.523 L 649.583 170.711 C 649.583 170.71 649.681 171.39 649.241 171.829 Z" />
                <polygon points="443.586 170.451 411.534 170.451 411.534 146.404 394.64 146.404 394.64 212.602 411.534 212.602 411.534 187.259 443.586 187.259 443.586 212.602 460.478 212.602 460.478 146.404 443.586 146.404" />
                <path d="M 562.097 146.404 L 545.118 146.404 L 545.118 197.088 L 510.212 197.088 L 510.212 146.404 L 493.233 146.404 L 493.233 197.088 C 493.233 197.088 493.048 204.072 496.938 208.117 C 501.251 212.601 505.73 212.601 505.73 212.601 L 549.586 212.601 C 549.586 212.601 554.661 212.369 558.414 207.882 C 562.226 203.325 562.084 197.087 562.084 197.087 L 562.097 197.087 L 562.097 146.404 Z" />
            </g>
        </svg>
    )
}

export default FhLogoInline
