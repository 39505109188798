import { AssessmentClass } from "@fithub-shared/anthropometry/assessment"
import React, { useState } from "react"
import { useQuery } from "react-query"
import api from "src/api"
import FetchWrapper from "src/components/FetchWrapper"
import AppHeader from "src/components/app-header"
import { useAuthContext } from "src/contexts/auth"
import { AssessmentItem, AssessmentList, Wrapper } from "./styles"
import moment from "moment"
import ContentView from "./ContentView"

// import { Container } from './styles';

const AssessmentView: React.FC = () => {
    const auth = useAuthContext()

    const [selected, setSelected] = useState<AssessmentClass>()

    function GetContent(id: string) {
        if (id === selected?.id) {
            setSelected(undefined)
            return
        }

        api.post<AssessmentClass>("/assessment/get", { id })
            .then((res) => setSelected(res.data))
            .catch((err) => alert(err))
    }

    const { data, isLoading } = useQuery("Assessments", () =>
        api
            .post<AssessmentClass[]>("/assessment/list", {
                user_id: auth.user?.id,
                created_by: auth.trainer?.id,
            })
            .then((res) => res.data)
            .catch((err) => {
                alert(err)
                return [] as AssessmentClass[]
            })
    )

    return (
        <>
            <AppHeader goBack title={"Avaliações"} />

            <Wrapper>
                <FetchWrapper isLoading={isLoading}>
                    <AssessmentList>
                        {data &&
                            data.map((e, index) => (
                                <AssessmentItem key={index}>
                                    <div
                                        style={{
                                            width: "100%",
                                            justifyContent: "space-between",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => GetContent(e.id)}
                                    >
                                        <p>{e.title}</p>
                                        <p>
                                            {moment(e.created_at).format(
                                                "MM/DD/YYYY"
                                            )}
                                        </p>
                                    </div>
                                    {selected && selected.id === e.id && (
                                        <ContentView assets={selected} />
                                    )}
                                </AssessmentItem>
                            ))}
                    </AssessmentList>
                </FetchWrapper>
            </Wrapper>
        </>
    )
}

export default AssessmentView
