import React from "react"
import { IconType } from "react-icons"
import { Wrapper } from "./styles"

// import { Container } from './styles';

interface IconButtonProps {
    title: string
    height?: number
    icon?: IconType
    onPress?: () => void
}

const ButtonIcon: React.FC<IconButtonProps> = (props) => {
    return (
        <Wrapper onClick={props.onPress} style={{ height: props.height }}>
            {props.icon && (
                <props.icon
                    style={{ marginRight: 10 }}
                    size={18}
                    color="var(--font)"
                />
            )}

            <p>{props.title}</p>
        </Wrapper>
    )
}

export default ButtonIcon
