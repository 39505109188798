import React, { useContext } from "react"
import { Route, Routes } from "react-router-dom"
import NotFound from "src/components/not-found"
import Dashboard from "../navigation/screens/dashboard"
import Login from "../navigation/screens/login"
import PlanView from "../navigation/screens/plan-view"
import WorkoutView from "../navigation/screens/plan-view/workout-view"
import ProtocolsList from "../navigation/screens/plans"
import StudentAuth from "../navigation/screens/trainee-auth"
import AuthContext from "src/contexts/auth"
import FirstAccess from "../navigation/screens/FirstAccess"
import Trainers from "../navigation/screens/trainers"
import { StyledRoutes } from "./styles"
import AssessmentView from "../navigation/screens/assessmentView"
import RecoverPass from "../navigation/screens/recover-pass"

// import { Container } from './styles';

const Rts: React.FC = () => {
    const { signed } = useContext(AuthContext)

    return signed ? <Signed /> : <Auth />
}

export default Rts

export const Auth: React.FC = () => {
    return (
        <Routes>
            <Route path={"*"} element={<NotFound redirect="/" />} />
            <Route path={"/"} element={<StudentAuth />} />
            <Route path={"/recover"} element={<RecoverPass />} />
            <Route path={"/aluno"} element={<FirstAccess />} />
        </Routes>
    )
}

export const Signed: React.FC = () => {
    const auth = useContext(AuthContext)

    return (
        <>
            <Routes>
                <Route path={"*"} element={<NotFound />} />
                <Route path={"/aluno"} element={<FirstAccess />} />
                <Route path={"/login"} element={<Login />} />
                <Route path={"/"} element={<Trainers />} />
                {auth.trainer !== null && (
                    <>
                        <Route path={"/home"} element={<Dashboard />} />
                        <Route path={"/plans"} element={<ProtocolsList />} />
                        <Route
                            path={"/plans/workouts"}
                            element={<PlanView />}
                        />
                        <Route
                            path={"/plans/workouts/view"}
                            element={<WorkoutView />}
                        />
                        <Route
                            path={"/assessments"}
                            element={<AssessmentView />}
                        />
                    </>
                )}
            </Routes>
        </>
    )
}
