import GlobalStyles from "src/assets/global-styles"
import React, { useState, useEffect, CSSProperties } from "react"
import ReactDOM from "react-dom"
import { styled, keyframes } from "styled-components"

export interface ModalProps {
    isOpen: boolean
    noStyles?: boolean
    onClose: () => void
    children?: React.ReactElement
    style?: CSSProperties
}

// Defina a animação
const fadeIn = keyframes`
    from {
        background-color: rgba(0, 0, 0, 0);
    }
    to {
        background-color: rgba(0, 0, 0, 0.8);
    }
`

const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Cor de fundo semi-transparente */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; /* Z-index alto para garantir que o modal fique sobre o conteúdo */

    animation: ${fadeIn} 150ms ease-in;
`

const ModalContent = styled.div`
    background-color: #444;
    padding: 20px;
    border-radius: 8px;
`

export const Modal: React.FC<ModalProps> = ({
    isOpen,
    onClose,
    children,
    noStyles,
    style,
}) => {
    const modalRoot = document.getElementById("app-root")
    const modalElement = document.createElement("div")

    useEffect(() => {
        if (isOpen && modalRoot) {
            modalRoot.appendChild(modalElement)
            return () => {
                modalRoot.removeChild(modalElement)
            }
        }
    }, [isOpen, modalElement, modalRoot])

    return isOpen && modalRoot
        ? ReactDOM.createPortal(
              <Backdrop onClick={onClose}>
                  {!noStyles && <GlobalStyles />}
                  {noStyles ? (
                      <div onClick={(e) => e.stopPropagation()}>{children}</div>
                  ) : (
                      <ModalContent
                          style={style}
                          onClick={(e) => e.stopPropagation()}
                      >
                          {children}
                      </ModalContent>
                  )}
              </Backdrop>,
              modalRoot
          )
        : null
}

const useModal = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false)

    const openModal = () => {
        setIsOpen(true)
    }

    const closeModal = () => {
        setIsOpen(false)
    }

    return {
        isOpen,
        openModal,
        closeModal,
    }
}

export default useModal
