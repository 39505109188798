import { styled } from "styled-components"
import { Column, Row } from "../../../../assets/global-styles"

export const DashboardBackground = styled.div`
    position: relative;
    overscroll-behavior-y: contain;

    flex-direction: column;
    flex: 1;
`

export const Header = styled(Column)`
    width: 100%;
    align-items: center;
`

export const Split = styled.div`
    height: 2px;
    margin-block: 10px;
    background-color: #ccc;
`

export const Logout = styled.button`
    position: absolute;
    right: 5px;
    height: 100%;
    aspect-ratio: 1;
    justify-content: end;

    align-items: center;
    cursor: pointer;

    span {
        margin: 5px;
    }
`

export const Notifications = styled(Column)`
    border-radius: 5px;
    padding: 10px;

    background-color: #0004;

    margin-block: 20px;

    opacity: 0.5;

    h4 {
        text-transform: uppercase;
        font-size: 12px;

        margin-bottom: 5px;
    }

    p {
        font-size: 12px;
    }
`

export const NotificationItem = styled.div<{ expand?: boolean }>`
    flex-direction: column;
    width: 100%;
    height: ${(p) => (p.expand ? "unset" : "20px")};
`

export const NotificationEmpty = styled(Column)`
    flex-direction: column;
    align-items: center;
    width: 100%;
    color: #888;
    font-weight: bold;
`

export const UserInfo = styled(Column)`
    align-items: center;
    width: 75%;
    justify-content: space-between;
    margin-bottom: 15px;
    border-bottom: 2px solid #ddd;
    p {
        margin: 15px;
        font-weight: bold;
        white-space: pre;
    }
`

export const Buttons = styled(Row)`
    button {
        width: 100%;
    }
`

export const PersonalInfo = styled.button`
    width: 100%;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-size: 12px;

    span {
        margin-right: 10px;
    }

    h4 {
        font-size: 12px;
    }

    h5 {
        text-transform: uppercase;
        opacity: 0.5;
    }

    button {
        align-items: center;
        background-color: #fff2;
        p {
            font-size: 12px;
            font-weight: bold;
            margin-right: 5px;
        }
    }
`
