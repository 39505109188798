import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Cookies from "js-cookie" // Importando js-cookie
import ProgressBar from "./ProgressBar"
import Controls from "./Controls"

interface TimerProps {
    maxTime: number
    tag: string
}

const TimerContainer = styled.div`
    position: relative;
    border: 1px solid #444;
    background-color: #0004;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 100%;
    border-radius: 10px;
    box-sizing: border-box;
`

const TimerHeading = styled.div`
    flex-direction: row;
    height: 100%;
    width: 100%;
    left: 10px;
    padding-right: 15px;
    position: absolute;
    z-index: 1;
    align-items: center;
    justify-content: space-between;

    h1 {
        font-weight: bold;
        color: white;
        font-size: 12px;
        padding-bottom: 2.5px;
    }
`

const ProgressBarContainer = styled.div`
    position: relative;
    flex: 1;
    height: 100%;
    overflow: hidden;
`

const Timer: React.FC<TimerProps> = ({ maxTime, tag }) => {
    const [currentTime, setCurrentTime] = useState(maxTime)
    const [running, setRunning] = useState(false)
    const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null)

    useEffect(() => {
        const startTime = Cookies.get(tag)

        if (startTime) {
            const elapsedTime = Math.floor(
                (Date.now() - parseInt(startTime, 10)) / 1000
            )
            const remainingTime = Math.max(maxTime - elapsedTime, 0)
            setCurrentTime(remainingTime)

            if (remainingTime > 0) {
                startTimer()
            }
        } else {
            setCurrentTime(maxTime)
        }
    }, [maxTime])

    const startTimer = () => {
        if (!Cookies.get(tag)) {
            Cookies.set(tag, Date.now().toString())
        }

        setRunning(true)
        const id = setInterval(() => {
            setCurrentTime((prevTime) => {
                if (prevTime === 0) {
                    clearInterval(id) // Pare o timer ao chegar a 0
                    return 0
                }
                return prevTime - 1
            })
        }, 1000)
        setIntervalId(id)
    }

    const pauseTimer = () => {
        setRunning(false)
        clearInterval(intervalId as NodeJS.Timeout)
        setIntervalId(null)
    }

    const resetTimer = () => {
        setRunning(false)
        setCurrentTime(maxTime)
        clearInterval(intervalId as NodeJS.Timeout)
        setIntervalId(null)
        Cookies.remove(tag)
    }

    return (
        <TimerContainer>
            <ProgressBarContainer>
                <TimerHeading>
                    <h1>DESCANSO</h1>
                    <h1>
                        {Math.floor(currentTime / 60)
                            .toString()
                            .padStart(2, "0")}
                        :{(currentTime % 60).toString().padStart(2, "0")}
                    </h1>
                </TimerHeading>
                <ProgressBar currentTime={currentTime} maxTime={maxTime} />
            </ProgressBarContainer>
            <Controls
                running={running}
                currentTime={currentTime}
                startTimer={startTimer}
                pauseTimer={pauseTimer}
                resetTimer={resetTimer}
            />
        </TimerContainer>
    )
}

export default Timer
