import React from "react"
import styled from "styled-components"

const ProgressBarContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 10px 0px 0px 10px;
`

const ProgressFill = styled.div`
    height: 100%;
    background-color: var(--primary);
    transition: width 0.1s;
`

const ProgressBar: React.FC<{ currentTime: number; maxTime: number }> = ({
    currentTime,
    maxTime,
}) => {
    return (
        <ProgressBarContainer>
            <ProgressFill
                style={{ width: `${(currentTime / maxTime) * 100}%` }}
            />
        </ProgressBarContainer>
    )
}

export default ProgressBar
