import { nanoid } from "nanoid"
import { WeightExercise } from "./exercise"
import { FaFire } from "react-icons/fa"
import { GiMuscleUp, GiWeightLiftingUp, GiRun } from "react-icons/gi"

export type MultiWorkout = Weight | Aerobic | Crossfit | Heating | undefined

export enum WorkoutType {
    undefined,
    weight,
    aerobic,
    crossfit,
    heating,
}

export function WorkoutTypeString(w: WorkoutType | undefined) {
    switch (w) {
        default:
            return "Criando..."
        case 1:
            return "Musculação"
        case 2:
            return "Aeróbico"
        case 3:
            return "Crossfit"
        case 4:
            return "Aquecimento"
    }
}

export type _Workout = Workout & { modified: boolean }

export class Workout {
    id: string
    title: string
    obs = ""
    created_at: Date
    type: WorkoutType = WorkoutType.undefined
    item: MultiWorkout

    constructor(t: WorkoutType, title: string, item?: MultiWorkout) {
        this.title = title
        this.id = nanoid() + Date.now()
        this.SetType(t, item)
        this.created_at = new Date()
    }

    SetType(t: WorkoutType, content?: MultiWorkout) {
        this.type = t

        switch (t) {
            case WorkoutType.weight:
                this.item = content ? (content as Weight) : new Weight()
                break
            case WorkoutType.aerobic:
                this.item = content ? (content as Aerobic) : new Aerobic()
                break
            case WorkoutType.crossfit:
                this.item = content ? (content as Crossfit) : new Crossfit()
                break
            case WorkoutType.heating:
                this.item = content ? (content as Heating) : new Heating()
                break
            case WorkoutType.undefined:
                this.item = undefined
                break
        }
    }
}

export function WorkoutIcon(w: WorkoutType) {
    switch (w as WorkoutType) {
        default:
            return GiMuscleUp
        case WorkoutType.weight:
            return GiMuscleUp
        case WorkoutType.crossfit:
            return GiWeightLiftingUp
        case WorkoutType.aerobic:
            return GiRun
        case WorkoutType.heating:
            return FaFire
    }
}

export class Weight {
    exercises: Array<WeightExercise>
    constructor(excs: Array<WeightExercise> = []) {
        this.exercises = excs
    }
    //Essa não é a estrutura de um treino genérico, é a estrutura de um treino de Musculação
    //É necessário ter o aquecimento como anexo separado. "interface Heating"
}

export class Aerobic {
    exercises: Array<AerobicExercise>
    constructor(excs: Array<AerobicExercise> = []) {
        this.exercises = excs
    }
    //Essa não é a estrutura de um treino genérico, é a estrutura de um treino de Musculação
    //É necessário ter o aquecimento como anexo separado. "interface Heating"
}

export class AerobicExercise {
    intensity = 0
    duration = 15
    obs = ""

    constructor() {
        console.log("criou aerobico")
    }
    //Essa não é a estrutura de um treino genérico, é a estrutura de um treino de Musculação
    //É necessário ter o aquecimento como anexo separado. "interface Heating"
}

export class Heating {
    instructions = ""

    constructor() {
        console.log("criou Heating")
    }
    //Essa não é a estrutura de um treino genérico, é a estrutura de um treino de Musculação
    //É necessário ter o aquecimento como anexo separado. "interface Heating"
}

export class Crossfit {
    instructions = ""

    constructor() {
        console.log("criou Crossfit")
    }
    //Essa não é a estrutura de um treino genérico, é a estrutura de um treino de Musculação
    //É necessário ter o aquecimento como anexo separado. "interface Heating"
}
