import React, { CSSProperties, InputHTMLAttributes, useState } from "react"
import { IconType } from "react-icons"
import { Area } from "../styles"
import { InputMask } from "src/modules/fithub-shared/functions/functions"
import { MdError } from "react-icons/md"
import { ValidatedField } from "src/modules/fithub-shared/interfaces/form"
// import { Container } from './styles';

interface CustomButton extends InputHTMLAttributes<HTMLInputElement> {
    error?: boolean
    icon?: IconType
    height?: number
    width?: number | string
    selectOnFocus?: boolean
    maxLength?: number
    minLength?: number
    iconColor?: string
    radius?: string
    autofocus?: boolean
    backgroundColor?: string
    align?:
        | "start"
        | "end"
        | "left"
        | "right"
        | "center"
        | "justify"
        | "match-parent"
    mask: InputMask
    onChangeText?: (value: string) => void
    onEndValidate?: (value: ValidatedField) => void
    onChangeValidate?: (value: ValidatedField) => void
}

const MaskInput: React.FC<CustomButton> = (props) => {
    const id = props.placeholder + "_input"

    const [error, setError] = useState(props.error)
    const [value, setValue] = useState<string>(props.defaultValue as string)
    const [typing, setTyping] = useState(false)

    const Ico = () => {
        if (props.icon === undefined) {
            return <></>
        } else {
            return (
                <div style={{ marginInline: 5 }}>
                    <button />
                    <props.icon color={"var(--font)"} size={18} />
                </div>
            )
        }
    }

    function _OnChange(e: React.ChangeEvent<HTMLInputElement>) {
        setValue(e.target.value.substring(0, props.mask.length))
        if (props.onChange) props.onChange(e)
        if (props.onChangeText) props.onChangeText(e.target.value)
        if (props.onChangeValidate)
            props.onChangeValidate({
                value: e.target.value,
                valid: !error as boolean,
            })
    }

    function _OnBlur(e?: React.FocusEvent<HTMLInputElement, Element>) {
        setTyping(false)
        props.onEnded && e && props.onEnded(e)
        setError(!props.mask.isValid(value))

        if (props.onEndValidate) {
            props.onEndValidate({
                value: value,
                valid: props.mask.isValid(value),
            })
        }
    }

    function _OnFocus(e: React.FocusEvent<HTMLInputElement, Element>) {
        setTyping(true)
        if (props.selectOnFocus) e.target.select()
    }

    function _OnEnd(e: React.SyntheticEvent<HTMLInputElement, Event>) {
        setTyping(false)
    }

    function clickPlaceholder() {
        setTyping(true)
        const input = document.getElementById(id) as HTMLInputElement | null
        if (input) {
            input.style.display = "block"
            input.focus()
            if (input.value.length > 0) input.select()
        }
    }

    const dynamicStyle = (hide: boolean) => {
        if (hide) {
            return {
                display: "hidden",
                width: 0,
                height: 0,
                direction: "rtl",
                backgroundColor: "red",
                color: "var(--font)",
            } as CSSProperties
        } else {
            return {
                width: "100%",
                textAlign: props.align ? props.align : "start",
                color: "var(--font)",
            } as CSSProperties
        }
    }

    function getProps() {
        return props as InputHTMLAttributes<HTMLInputElement>
    }

    return (
        <Area
            radius={props.radius}
            onClick={clickPlaceholder}
            style={{
                flexDirection: "row",
                width: props.width,
                position: "relative",

                borderColor: error ? "red" : "var(--border)",
            }}
        >
            <Ico />

            <input
                {...getProps()}
                tabIndex={props.tabIndex}
                id={id}
                type={props.mask.type}
                maxLength={props.mask.length}
                value={value}
                style={dynamicStyle(!typing)}
                onChange={_OnChange}
                onBlur={_OnBlur}
                onEnded={_OnEnd}
                onFocus={_OnFocus}
            />
            {!typing && (
                <p
                    style={dynamicStyle(typing)}
                    onClick={clickPlaceholder}
                    onFocus={clickPlaceholder}
                    onSelect={clickPlaceholder}
                    id={id + "_fake"}
                >
                    {value ? props.mask.func(value) : props.placeholder}
                </p>
            )}

            {value && error && (
                <MdError size={24} style={{ marginRight: 3 }} color="red" />
            )}
        </Area>
    )
}

export default MaskInput
