import moment from "moment"
import { ValidateCPF, ValidateDate } from "./validators"

export class InputMask {
    type = ""
    func: (text: string) => string
    private validation: (text: string, m: InputMask) => boolean
    length = 0

    constructor(
        type: string,
        func: (text: string) => string,
        valid: (text: string, m: InputMask) => boolean,
        length = 0
    ) {
        this.type = type
        this.func = func
        this.validation = valid
        this.length = length
    }

    isValid(value: string | undefined) {
        if (!value) return false
        return this.validation(value, this)
    }
}

export function NormalizeNumArray(arr: number[]) {
    return arr.map((e) => (isNaN(e) ? 0 : e))
}

export function ConvertDate(date: string) {
    if (date.length === 6) return moment(date, "DDMMYY").format("YYYY-MM-DD")
    else return moment(date, "DDMMYYYY").format("YYYY-MM-DD")
}

export const scheduleDateMask = new InputMask(
    "number",
    function ScheduleFormat(dataOriginal: string) {
        // Verifica se a dataOriginal está no formato correto (ddmmaaaa)
        const regexData = /^(\d{2})(\d{2})(\d{4}|\d{2})$/

        return dataOriginal.replace(regexData, "$1/$2/$3")
    },
    function ScheduleValidation(data: string) {
        const d = ValidateDate(
            data,
            data.length === 8 ? "DD/MM/YYYY" : "DD/MM/YY"
        )

        return d.isValid() && d.diff(new Date(), "days") >= 0
    },
    8
)

export const birthdateMask = new InputMask(
    "number",
    function BirthdateFormat(dataOriginal: string) {
        // Verifica se a dataOriginal está no formato correto (ddmmaaaa)
        const regexData = /^(\d{2})(\d{2})(\d{4}|\d{2})$/

        return dataOriginal.replace(regexData, "$1/$2/$3")
    },
    function BirthdateValidation(data: string) {
        return ValidateDate(data, "DD/MM/YYYY").isValid()
    },
    8
)

export const phoneMask = new InputMask(
    "phone",
    function PhoneFormat(number: string) {
        if (number.startsWith("+")) return number
        else
            return number
                .substring(0, 11)
                .replace(/(\d{2})(\d{4,5})(\d{4})/g, "($1) $2-$3")

        //return number.replace(/(\d{2})(\d{5})(\d{4})/g, "($1) $2-$3")
    },
    function PhoneValidation(data: string) {
        return data.length >= 4
    },
    14
)

export const identityMask = new InputMask(
    "phone", // Alterado de number para phone, para que ao colar um cpf com pontuação possa funcionar o replace corretamente
    function IdentityFormat(number: string) {
        return number.replace(/(\d{3})/g, "$1 ")
    },
    function IdentityValidation(value: string) {
        return value.length > 0
    },
    20
)

export const cpfMask = new InputMask(
    "number",
    function CPFFormat(number: string) {
        if (number.length === 11)
            return number.replace(
                /(\d{3})(\d{3})(\d{3})(\d{2})/g,
                "$1.$2.$3-$4"
            )
        else if (number.length === 14)
            return number.replace(
                /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                "$1.$2.$3/$4-$5"
            )
        else return number
    },
    function CPFValidation(value: string) {
        return ValidateCPF(value)
    },
    15
)
