import ReactApexChart from "react-apexcharts"
import styled from "styled-components"

export const ChartWrapper = styled.div`
    flex-direction: column;
    overflow-y: hidden;
    overflow-x: hidden;

    align-items: center;

    border-radius: 5px;
    foreignObject {
        display: none !important;
    }
`

export const ChartWrapperAssessment = styled.div`
    flex-direction: column;
    overflow-y: hidden;
    overflow-x: hidden;

    width: 100%;

    align-items: center;

    border-radius: 5px;
    foreignObject {
        display: none !important;
    }
`

export const Apex = styled(ReactApexChart)<{ height: string }>`
    padding: 0px;
    height: ${(p) => p.height};
    max-height: 250px;

    * {
        transition: fill 1s;
    }
`
