import React, { useEffect, useState } from "react"
import { IoSettingsSharp } from "react-icons/io5"
import { Modal } from "src/components/Modal"
import { FeedbackArea } from "../../WeightStyles"
import api from "src/api"
import { useAuthContext } from "src/contexts/auth"

// import { Container } from './styles';

const ModalEquipament: React.FC<{
    linkid: string
    isOpen: boolean
    onClose: () => void
}> = ({ linkid, isOpen, onClose }) => {
    const auth = useAuthContext()

    const [df, setDf] = useState<string>("")

    useEffect(() => {
        if (isOpen) Get()
    }, [linkid, isOpen])

    function Get() {
        if (!auth.user) {
            alert("user null")
            return
        }

        api.post<string>("/trainee/exercise/settings", {
            user_id: auth.user.id,
            linkid,
        })
            .then((res) => {
                setDf(res.data)
            })
            .catch((err) => alert(err))
    }

    function Submit() {
        if (!auth.user) return
        if (df === "") {
            onClose()
            return
        }
        api.post<string>("/trainee/exercise/settings-update", {
            user_id: auth.user.id,
            linkid,
            content: df,
        })
            .then(onClose)
            .catch((err) => alert(err))
    }

    return (
        <>
            {isOpen && (
                <Modal isOpen={isOpen} onClose={Submit}>
                    <FeedbackArea>
                        <div className="title">
                            <h4>
                                Ajustes do Equipamento
                                {/*type === 0 ? "FeedBack" : */}
                            </h4>
                            <IoSettingsSharp />
                            {/*type === 0 ? <IoChatboxEllipses /> :*/}
                        </div>
                        <textarea
                            value={df}
                            onChange={(e) => setDf(e.target.value)}
                        />

                        <button onClick={Submit}>Salvar</button>
                    </FeedbackArea>
                </Modal>
            )}
        </>
    )
}

export default ModalEquipament
