import React, { useEffect, useState } from "react"
import { Modal } from "src/components/Modal"
import { FeedbackArea } from "../../WeightStyles"

import { IoChatboxEllipses } from "react-icons/io5"

// import { Container } from './styles';

const ModalFeedback: React.FC<{
    defaultValue?: string
    isOpen: boolean
    onClose: (value: string) => void
}> = ({ defaultValue, isOpen, onClose }) => {
    const [df, setDf] = useState<string>(defaultValue ?? "")

    useEffect(() => {
        setDf(defaultValue ?? "")
    }, [isOpen, defaultValue])

    function Submit() {
        onClose(df)
    }

    return (
        <>
            {isOpen && (
                <Modal isOpen={isOpen} onClose={Submit}>
                    <FeedbackArea>
                        <div className="title">
                            <h4>FeedBack</h4>
                            <IoChatboxEllipses />
                        </div>
                        <textarea
                            value={df}
                            onChange={(e) => setDf(e.target.value)}
                        />

                        <button onClick={Submit}>Salvar</button>
                    </FeedbackArea>
                </Modal>
            )}
        </>
    )
}

export default ModalFeedback
