import React from "react"
import { useNavigate } from "react-router-dom"
import { useQuery } from "react-query"
import api from "../../../../api"
import { Button, Header, Observations, ScrollView, Wrapper } from "./styles"
import moment from "moment"
import AppHeader from "../../../../components/app-header"

import { Column, Row } from "../../../../assets/global-styles"
import { PiFireBold } from "react-icons/pi"
import { HiChevronRight } from "react-icons/hi"
import { ProtocolType } from "../../../fithub-shared/interfaces/protocol"
import {
    Workout,
    WorkoutType,
    WorkoutTypeString,
} from "../../../fithub-shared/interfaces/workout"
import EmptyArray from "src/components/EmptyArray"
import { GetParameter } from "src/assets/functions"

import LoadingIndicator from "src/components/LoadingIndicator"
import StartedShortcut from "src/components/StartedShortcut"

// import { Container } from './styles';

export async function fetchPlan(id: string) {
    const res = await api.post<ProtocolType>("/protocols/get", { id: id })
    return res.data
}

export async function fetchWorkout(plan: string, workout: string) {
    return api
        .post<Workout>("/protocols/get/workout", {
            plan,
            workout,
        })
        .then((res) => res.data)
}

const PlanView: React.FC = () => {
    const navigate = useNavigate()

    const planId = GetParameter("pid")

    const { data, isLoading } = useQuery(["planWorkouts", planId], () =>
        fetchPlan(planId)
    )

    function GoToWorkout(e: Workout) {
        console.log("Go to workout", e.id)
        //wh.setWorkout(e)
        console.log(JSON.stringify(localStorage))

        navigate(`view?pid=${planId}&wid=${e.id}`)
    }

    return (
        <Wrapper>
            <AppHeader goBack title={"TREINOS"} />
            <div style={{ width: "100%", padding: 10 }}>
                <StartedShortcut />
            </div>
            {isLoading || !data ? (
                <LoadingIndicator isLoading={isLoading} />
            ) : (
                <>
                    {data.obs && (
                        <Observations className="shadow">
                            <Header>
                                <h5>Observações:</h5>
                                <h5>
                                    {moment(data.created_at).format(
                                        "DD/MM/yyyy"
                                    )}
                                </h5>
                            </Header>

                            <p>{data.obs}</p>
                        </Observations>
                    )}
                    <ScrollView>
                        {data.workouts.length > 0 ? (
                            data.workouts.map((e, i) => {
                                return (
                                    <Button
                                        key={i}
                                        onClick={() => GoToWorkout(e)}
                                    >
                                        <Row>
                                            <PiFireBold
                                                size={24}
                                                style={{ marginRight: 10 }}
                                                color="var(--font)"
                                            />
                                            <Column>
                                                <p>{e.title}</p>
                                                <h5>
                                                    {WorkoutTypeString(
                                                        e.type as WorkoutType
                                                    )}
                                                </h5>
                                            </Column>
                                        </Row>

                                        <HiChevronRight
                                            size={24}
                                            style={{ marginRight: 10 }}
                                            color="var(--font)"
                                        />
                                    </Button>
                                )
                            })
                        ) : (
                            <EmptyArray itemTitle="Treinos" />
                        )}
                    </ScrollView>
                </>
            )}
        </Wrapper>
    )
}

export default PlanView
