import GlobalStyles, {
    MobileContainer,
    MobileBorders,
} from "./assets/global-styles"
import { BrowserRouter } from "react-router-dom"
import {
    QueryClient,
    QueryClientConfig,
    QueryClientProvider,
} from "react-query"
import { AuthProvider } from "./contexts/auth"
import Rts from "./modules/routes"
import SvgTop from "./components/SvgTop"

const config: QueryClientConfig = {
    defaultOptions: {
        queries: { refetchOnWindowFocus: false },
    },
}

function App() {
    const queryClient = new QueryClient(config)

    return (
        <MobileContainer>
            <GlobalStyles />

            <MobileBorders id="app-root" className="dark">
                <SvgTop />

                <QueryClientProvider client={queryClient}>
                    <BrowserRouter>
                        <AuthProvider>
                            <Rts />
                        </AuthProvider>
                    </BrowserRouter>
                </QueryClientProvider>
            </MobileBorders>
        </MobileContainer>
    )
}

export default App
