import React from "react"
import { IconType } from "react-icons"
import { styled } from "styled-components"

// import { Container } from './styles';

interface ClicableIconProps {
    icon: IconType
    size?: number
    title?: string
    onPress?: () => void
}

const Wrapper = styled.button`
    cursor: pointer;
`

const ClickableIcon: React.FC<ClicableIconProps> = (props) => {
    return (
        <Wrapper onClick={props.onPress}>
            <props.icon
                color="var(--font)"
                style={{ marginRight: 10 }}
                size={props.size}
                title={props.title}
            />
        </Wrapper>
    )
}

export default ClickableIcon
